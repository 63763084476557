@use "./global";

.wrap {
    display: flex;
    flex: 1;
    // flex-basis: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    padding: 18px 0;
    height: 94px;
    color: #00266A;
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(123, 129, 149, 0.12);
    align-self: stretch;

    @include global.bp-mobile-up {
        width: 100%;
    }

    @include global.bp-mobile-down {
        //margin: 0 16px;
        height: 150px;
    }

    &__container {
        display: flex;
        flex: 1;
        max-width: 1180px;
        justify-content: space-between;
        //margin: 0 169px;

        @include global.bp-desktop-down {
            margin: 0 30px;
        }


    }

}

.logoAndLinks {
    display: flex;
    flex: 1;
    align-items: center;
}

