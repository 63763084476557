@use "./global";

.wrap {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    gap: 20px;
    text-wrap: nowrap;

    @include global.bp-laptop-down {
        margin-left: 30px;
    }

    @include global.bp-tablet-down {
        margin-left: 40px;
        flex-direction: column;
    }

    @include global.bp-mobile-down {
        margin-left: 20px;
    }

}
